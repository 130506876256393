<template>
  <div class="tokyo_tm_contact">
    <div class="article_title">
      <div class="title_flex">
        <div class="left">
          <span>Contactez-moi</span>
          <h3 class="article_subtitle">Par téléphone</h3>
           <p>
            Je prends les rendez-vous et je réponds à vos questions par téléphone uniquement : <br />
            06 13 37 64 66
          </p>
          <br />
        </div>
      </div>
    </div>
    <!-- End Title -->

    
    <div class="title_flex">
      <div class="left article_subtitle">
        <h3>Plan d'accès</h3>
      </div>
    </div>
    

    <div class="map_wrap">
      <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2641.5916275432096!2d7.4503018156631775!3d48.54105737925773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796adcb20585851%3A0xea6cbf38e986dcb3!2s2%20Rue%20de%20la%20Trinit%C3%A9%2C%2067190%20Mutzig!5e0!3m2!1sfr!2sfr!4v1629839019675!5m2!1sfr!2sfr" 
        width="600" 
        height="450" 
        style="border:0;" 
        allowfullscreen="" 
        loading="lazy">
      </iframe>
    </div>
    <!-- End Google Map -->

  </div>
</template>

<script>
  export default {
    components: {
    },
  };
</script>
