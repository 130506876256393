<template>
  <div class="tokyo_tm_news">
    <div class="article_title">
      <div class="title_flex">
        <div class="left">
          <span>Credits</span>
          <!-- <h3>Latest News</h3> -->
        </div>
      </div>
    </div>
    <!-- END TITLE -->
    <div class="tokyo_tm_modalbox_news">
      
      <div class="details"><div class="extra"></div></div>

      <div class="main_content ">
        <div class="descriptions"> 
          <h3 class="article_subtitle">
            Mentions légales       
          </h3>  
          <p>
            Le site www.simonemarck.com est édité par Simone Marck, également responsable éditoriale et 
            titulaire de tous les droits de propriété intellectuelle sur l'ensemble des contenus. 
            Toute reproduction partielle ou totale du contenu de ce site est soumise à une autorisation 
            préalable de la part de son éditrice.
          </p>
          <h4 class="paragraphe_title">
            Editeur       
          </h4> 
          <p>
            Simone Marck
            <br />2, rue de la Trinité
            <br />67190 Mutzig, France
            <br />Tél. : 06 13 37 64 66

            <br /><br />Numéro de Siret [.....]
            <br />Code NAF [.....]
            <br />
          </p>
          <h4 class="paragraphe_title">
            Crédits       
          </h4> 
          <p>
            <b>Textes</b>
            <br />Éliane Autran

            <br /><br /><b>Photographies</b>
            <br />Simone Marck
            <br />Anaïs Schlienger
            <br />ou mentionné en légende si autre

            <br /><br /><b>Conception-réalisation</b>
            <br />Anaïs Schlienger
            <br />270 rue Saint-Jacques
            <br />75005 Paris, France
            <br />Tél. : 06 26 63 96 58
            <br />Courriel : anais.schlienger@lilo.org
         </p>
          <h4 class="paragraphe_title">
            Propriété intellectuelle         
          </h4>  
          <p>
           L'éditeur du site est titulaire exclusif de tous les droits de propriété intellectuelle 
           sur les contenus du site www.eliane-autran.fr. Le code de la propriété intellectuelle interdit 
           les copies ou reproductions destinées à une utilisation collective. 
           Toute représentation ou reproduction intégrale ou partielle faite par quelque procédé 
           que ce soit, sans le consentement de l'éditeur du site est illicite et constitue une 
           contrefaçon sanctionnée par les articles 425 et suivants du Code pénal.
          </p>
          <h4 class="paragraphe_title">
            Informatique et libertés        
          </h4> 
          <p>
            Conformément aux préconisations de la Cnil et à la loi Informatique et Libertés n° 78-17 
            du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, vous disposez 
            d'un droit de consultation, de rectification et de suppression des données personnelles 
            vous concernant communiquées à notre société. Pour l'exercer, adressez-vous au webmestre 
            du site via le formulaire de contact.
          </p>
        </div>
        <!-- END DESCRIPTION --> 
        <div class="footer">
          <div class="details"><div class="extra"></div></div>
          <p>Par Simone Marck</p>
        </div>                  
      </div>
      <!-- END CONTENT -->
    </div>
    <!-- END ARTICLE TEXT -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
