<template>
  <div class="tokyo_tm_news">
    <div class="article_title">
      <div class="title_flex">
        <div class="left">
          <span>Relaxation</span>
          <!-- <h3>Latest News</h3> -->
        </div>
      </div>
    </div>
      <div class="tokyo_tm_modalbox_news">
      <div class="details">
        <div class="extra"></div>
        <h3 class="title">
          Séance de relaxation                
        </h3>
      </div>
      <!-- END DETAILS -->
  
      <div class="main_content ">
        <div class="descriptions">
          <p>
            La relaxation est à la fois un état et une méthode qui consiste à se libérer des tensions physiques <u>et</u> 
            psychiques.
          </p>         
          <p>
            Concrêtement cela se passe de la manière suivante :
            <br/>- La personne est installée confortablement (ambiance calme)
            <br/>- elle va suivre les indications données verbalement
            <br/>- apprentissage ou exercices avec la respiration
            <br/> -ressentir son corps, prendre conscience de soi
            <br/>- exercices de visualisation pour amner le changement
          </p>
          <div class="quotebox">
            <div class="icon"><img class="svg" src="../assets/img/svg/quote.svg" alt="tumb" /></div>
            <p>Comme tu diriges ton souffle, tu diriges ta vie <br/>- Proverbe indien</p>
          </div>
          <div class="image">
            <img src="../assets/img/bowl.jpg" alt="tumb" />
            <p class="image_legend">
              Photo par Simone Marck 
            </p>
          </div>
          <div class="quotebox">
            <div class="icon"><img class="svg" src="../assets/img/svg/quote.svg" alt="tumb" /></div>
            <p>La plus importante et la plus négligée de toutes les conversations,
              c'est l'entretien avec soi-même <br/>-Chancelier Ovenstiern</p>
          </div>
          <p>
            Cette méthode permet de diminuer ou de supprimer des troubles d'origine psychologique ou physiologiques.
            Cette technique améliore la qualité de vie. 
          </p>
          <!-- END QUOTEBOX -->
          <div class="footer">
            <div class="details"><div class="extra"></div></div>
            <p>Par Simone Marck</p>
          </div>
        </div>
        <!-- END DESCRIPTION -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
